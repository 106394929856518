import { BackgroundType, Meta } from "."
import { get } from "../request"

export interface GetCustom404Type {
    data: {
        id: number
        attributes: Custom404Attributes
    }
    meta: Meta
}


export interface Custom404Attributes {
    createdAt: string
    updatedAt: string
    publishedAt: string
    image: BackgroundType
}

export const getCustom404 = (): Promise<{ data: GetCustom404Type }> => {
    return get(
        `${process.env.NEXT_PUBLIC_API_ENDPOINT}/api/custom404?populate=image`
    )
}