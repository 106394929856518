import Layout from "../components/Layout/Layout";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import { getCustom404 } from "./../utils/requests/rayongboard/404";
import Image from "next/image";
import { useEffect, useState } from "react";

export interface Custom404Props {
  image?: string | null;
}
const Custom404 = ({ image }: Custom404Props) => {
  const [image404, setImage404] = useState(
    "/uploads/default_026_df0f5889f7.webp"
  );
  const [firstPath, setFirstPath] = useState(false);
  const router = useRouter();
  const lang = router.locale;
  const path = router.asPath;

  useEffect(() => {
    onInit();
  }, []);

  const onInit = async () => {
    const firstPath = path.startsWith("/blogs");
    setFirstPath(firstPath);
    await getCustom404()
      .then((custom404Data) => {
        if (custom404Data.data.data?.attributes?.image?.data?.attributes.url) {
          setImage404(
            custom404Data.data.data?.attributes?.image?.data?.attributes.url
          );
        }
      })
      .catch((err) => {
        console.error("getCustom404 error:", err);
      });
  };

  return (
    <>
      <Head>
        <title>404 Not Found</title>
        <meta property="og:title" content="404 Not Found" />
        <meta property="twitter:title" content="404 Not Found" />
      </Head>
      <main className="h-screen">
        <section className="h-screen flex">
          <div className="container px-4 m-auto">
            <div className="max-w-4xl h-64 md:h-96 mb-12 mx-auto">
              <Image
                className="h-full w-full rounded-lg object-cover"
                src={`${process.env.NEXT_PUBLIC_API_ENDPOINT}${image404}`}
                alt="404-not-found-image"
                width={896}
                height={384}
              />
            </div>
            <div className="text-center">
              <p className="text-xs font-bold text-gray-200 uppercase">
                Error 404
              </p>
              <h1 className="mb-2 text-4xl lg:text-5xl font-bold font-heading">
                Page not found
              </h1>
              <p className="mb-6 text-lg text-gray-500 leading-loose">
                Sorry! We are unable to find the page you are looking for
              </p>
              <div className="flex flex-wrap justify-center">
                {firstPath ? (
                  <Link
                    className="px-6 py-2 mr-4 bg-gray-500 hover:bg-gray-600 text-sm leading-loose text-gray-50 font-semibold rounded"
                    href={`/${lang}/blogs`}
                  >
                    Go back to Blogs
                  </Link>
                ) : (
                  <Link
                    className="px-6 py-2 mr-4 bg-gray-500 hover:bg-gray-600 text-sm leading-loose text-gray-50 font-semibold rounded"
                    href={`/${lang}`}
                  >
                    Go back to Homepage
                  </Link>
                )}

                <button
                  className="px-6 py-2 border hover:border-gray-400 text-sm font-semibold leading-loose rounded"
                  onClick={router.reload}
                >
                  Try Again
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
export default Custom404;

// export async function getStaticProps({ locale }: { locale: string }) {
//   let props: Custom404Props = {
//     image: null,
//   };
//   await getCustom404()
//     .then((custom404Data) => {
//       props.image =
//         custom404Data.data.data?.attributes?.image?.data?.attributes.url ||
//         "/uploads/default_026_67eccef8f1.webp";
//     })
//     .catch((err) => {
//       console.error("getCustom404 error:", err);
//     });
//   return {
//     props: props,
//   };
// }
